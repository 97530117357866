const config = {
    appName: "CRT Business Tool - sbx",
    region: "us-east-1",
    cognito: {
        userPoolId: "us-east-1_Arab2yHrM",
        domain: "client-reporting-user-pool-domain.auth.us-east-1.amazoncognito.com",
        tokenUrl: "https://client-reporting-user-pool-domain.auth.us-east-1.amazoncognito.com/oauth2/authorize",
        providerId: "ce-crt-pool-provider",
        redirectUrlSignIn: "https://crt.assetmgmt.dev.principalam.com",
        redirectUrlSignOut: "https://crt.assetmgmt.dev.principalam.com/logout",
        clientId: "1aljj13hbp1vglagv76rl6gr0c",
        scope: "email openid aws.cognito.signin.user.admin",
        readOnlyGroup: null,
        updateGroup: null
    },
    api: {
        partyBaseAPI: "https://jxchfxzdf2.execute-api.us-east-1.amazonaws.com/sbx",
        domainBaseAPI: "https://0zybbxjo1h.execute-api.us-east-1.amazonaws.com/sbx"
    }
};

export default config;
